@import "../../../assets/scss/main.scss";

.homeScreenWrapper {
    overflow: hidden !important;
    @include screenPaddingTop;

    .btn {
        position: relative;
        top: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        font-family: $primaryFontName;

        .enquirebtn {
            background-color: $steelBlue;
            color: $whiteColor;
            width: 11em;
            height: 3em;
            border-radius: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: $primaryFontName;
            font-size: 1.1em;

            .arrowIcon {
                padding-left: 1.5em;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .videobtn {
            background-color: $whiteColor;
            color: $secondaryColor;
            width: 11em;
            height: 3em;
            border-radius: 3em;
            border: 1px solid $secondaryColor;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: $primaryFontName;
            font-size: 1.1em;
        }

        @include for_media(mobileScreen) {
            flex-direction: column;
            margin: auto;
            gap: 10px;
        }

        .playIcon {
            padding-left: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mainContentContainer {
        @include for_media(mobileScreen) {
            overflow-x: hidden;
        }

        .homeFlowerImage {
            position: absolute;
            top: 38em;
            left: 0;
            width: 16.5em;
            z-index: -1;
            height: 24em;

            @include for_media(mobileScreen) {
                top: 53em;
                width: 9em;
                height: 11em;
            }
        }

    }

    @include for_media(mobileScreen) {
        flex-direction: column;
        position: inherit;
    }

    .mainContent {
        margin: 5em 0em;
        padding: 5em 10em;
        background-color: $lightPrimaryColor;
        div{
            text-align: justify;
        }
        @include for_media(mobileScreen) {
            flex-direction: column;
            position: inherit;
            margin: 3em 0em;
            padding: 1em;
        }
    }

    .dotedImage {
        position: absolute;
        right: 0;
        top: 32em;
        width: 15em;

        @include for_media(mobileScreen) {
            position: absolute;
            right: 0;
            top: 71em;
            width: 12em;
            height: 22em;
        }
    }

    .imageFrame {
        position: relative;
        margin-top: 4em;
        width: 37em;
        left: 0;
        height: 33em;

        @include for_media(mobileScreen) {
            margin-top: 1em;
            width: 100%;
            height: 90%;
        }
    }
}

.specialContent {
    font-family: "Roboto Slab";
    font-weight: 400;
    line-height: 1.6;
    text-align: justify;

    @include for_media(mobileScreen) {
        list-style-type: none;
        text-align: left;
        margin: auto;
        width: 90%;
    }

    .contentHeader {
        font-family: $primaryFontName;
        font-size: xx-large;
        color: $primaryColor;
        text-transform: uppercase;
        text-align: center;
        font-family: $secondaryFontName !important;
        letter-spacing: 5px;
        margin-bottom: 2em;
        a{
            color: $secondaryColor;
        }
        @include for_media(mobileScreen) {
            margin: 1em;
            font-size: 1.5em;
            text-align: center;
        }
    }
}



.integrateSchoolWrapper{
    display: flex;
    // border-top: 1px solid $black;
    // border-bottom: 1px solid $black;
    // padding: 2em 10em 2em 10em;
    // justify-content: space-evenly;
    align-items: center;
    gap: 5em;

    .sclDiv {
        width: 45%;
        img {
            width: 100%

        }
    }

    .sclContentDiv{
        width:55%;
        text-align: justify;
        font-family: $primaryFontName;
        padding-right: 5rem;
        .knowMoreBtn{
            background-color: $secondaryColor;
            color: $whiteColor;
            border-radius: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15em;
            height: 2.3em;
            cursor: pointer;
            @include for_media(mobileScreen) {
                margin: auto;
                width: 12em;
            }
        }
        p{
            font-size: 1rem;
            font-weight: bold;
        }
    }   
}
.sclContentMblWrapper{
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    .knowMoreMblBtnWrapper{
        display: flex;
        justify-content: center;
        .knowMoreMblBtn{
            background-color: $secondaryColor;
            color: $white;
            border-radius: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 9em;
            height: 2.3em;
            cursor: pointer;
        }
    }
    p{
        text-align: center;
        font-size: small;
    }
    img {
        width: 100% !important;
    }
}
.sclContentHeader{
    color: $primaryColor;
    word-spacing: 5px;
    letter-spacing: 0.2em;
    text-align: left;
    font-size: 2vw;
    a{
        color: $secondaryColor;
    }
    @include for_media(mobileScreen){
        text-align: center;
        font-size: xx-large;
        img{
            width: 50% !important;
        }
    }
}

.featureMobile{
    display: flex;
    flex-direction: column;
}

.featureContentMobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .eduModalMobile{
        margin-top: 35px;
        height: 50%;
        .educontentMblWrapper{
            position: relative;
            .eduSegemtMbl{
                position: absolute;
                top: 0;
                width: 100%;
                box-shadow: none;
                border: none;
                background: transparent;
                .eduSegmentMblHeader{
                    font-family: $secondaryFontName;
                    font-weight: bold;
                    text-align: center;
                    font-size: xx-large;
                }
                .eduContent{
                    border: 3px dashed $grayLight1;
                    border-radius: 15px;
                    .slider{
                        min-height: 16em !important;
                    }
                    .divWrapper{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        background: transparent;
                        .childDiv{
                            width: 70%;
                            display: flex;
                            justify-content: space-between;
                            z-index: 1;
                            background: transparent;
                        }
                    }
                    img{
                        position: absolute;
                    }                    
                    .vectorImgMbl{
                        width: 29%;
                    }
                    .eduActiveHeaderMbl{
                        font-size: 1rem;
                        font-weight: bold;
                        color: $primaryColor;
                        margin-bottom: 0;
                    }
                    .ulTag {
                        list-style-type: none;
                        margin-left: -2em;
                        font-size: small;
                        margin-top: 0;
                    }
                    .ulTag li::before {
                        content: "- ";
                        margin-right: 8px;
                        line-height: 1.5;
                    }                    
                    .rightArrowImage{
                        width: 2em;
                        height: 2em;
                        right: 6em;
                        bottom: 0em;
                        position: static;
                        cursor: pointer;
                    }
                    .leftArrowImage{
                        width: 2em;
                        height: 2em;
                        left: 3em;
                        bottom: 0em;
                        position: static;
                        cursor: pointer;
                    }
                    .sliderDiv{
                        padding: 15px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        max-height: 230px;
                    }
                }
            }
        }
        img{
            width: 100%;
        }
    }
}

.features {
    // margin-top: 5em;
    // display: none ;
    margin: 0 auto;


    .featuresHeader {
        text-align: center;
        font-size: 3rem;
        padding-top: 2rem;

        @include for_media(mobileScreen) {
            top: 0em;
        }
    }

    .gridColumn{
        padding: 0 !important;
    }

    .featureContainer{
        height: 500px;
        margin: 0 10rem;

        .featureGrid{
            width: 100%;
            height: 100%;
        }
    }
    

    .platFormWrapper{
        width: 100%;
        height: 100%;
        padding: 4em 1rem;
        min-height: 100%;
        div:nth-child(2) {
            border: none !important;
            display: flex;
        }

    }

    .eduWrapper{
        width: 100%;
        height: 100%;
        padding: 4em 1rem;
        min-height: 100%;
    }

    .eduTitle1{
        font-size: large;
        cursor: pointer;
        line-height: normal;
        margin: 10px 0px;
    }
    .eduActiveTitle1{
        font-size: large;
        color: $secondaryColor !important;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: $black;
        text-underline-offset: 4px;
        line-height: normal;
    }

    .eduContentWrapper{
        background-color: $white;
        opacity: 0.9;
        position: relative;
        width: 100%;
        height: 100%;
        font-family: "Lato" !important;
        .eduSegmentWrapper{
            display: flex !important;
            flex-direction: row !important;
            background-color: $warmPeach !important;
            border-radius: 7px;
            background: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            padding: 1em !important;
            .eduSegment1{
                border: none;
                box-shadow: none;
                width: 50%;
                li{
                    font-size: 1rem;
                }
                .eduSegmentHeader{
                    color: $primaryColor;
                    font-family: $secondaryFontName;
                    font-size: xx-large;
                    font-weight: bold;
                }
                .eduTitle{
                    font-size: large;
                    cursor: pointer;
                    line-height: normal;
                    margin: 10px 0px;
                }
                .eduActiveTitle{
                    font-size: large;
                    color: $secondaryColor;
                    cursor: pointer;
                    font-weight: bold;
                    text-decoration: underline;
                    text-decoration-color: $black;
                    text-underline-offset: 4px;
                    line-height: normal;
                    margin: 10px 0px;
                }
            }
            .segment2Div{
                overflow-y: scroll ;
            }
            .eduSegment2{
                width: 50%;
                border: none;
                font-size: 0.8rem !important;
                img{
                    width: 100%;
                    object-fit: contain;
                }
                border: 4px dashed $grayLight1;
                border-radius: 15px;
                margin-bottom: 1em;
                p{
                    font-size: 1.2rem;
                }
                ul {
                    list-style-type: none;
                    margin-left: -2em;
                    font-size: 1rem;
                }
                ul li::before {
                    content: "- ";
                    margin-right: 8px;
                }
                .eduActiveHeader{
                    font-weight: bold !important;
                    color: $secondaryColor;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.comingSoon{
    display: flex;
    justify-content: center;
}



.latestBlog {
    margin-top: 5em;
    height: 40em;

    @include for_media(mobileScreen) {
        height: 62em;
    }
}

.reviewSection {
    width: 74em;
    height: 25em;
    margin: 5em 0em 0em 13em;

    @include for_media(mobileScreen) {
        margin: 2em 0em 0em 1em;
        width: 26em;
        height: 33em;
        padding: 1em;
    }
}

.arrowWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.splContentWrapper{
    @include for_media(mobileScreen){
        padding: 1em;
    }
}
.programContainer{
    .programHead{
        letter-spacing: 0.2em !important;
        text-align: center;
        font-size: xx-large;
    }
}