@import "../../../assets/scss/main.scss";

.programWrapper{
    background-image: url('../../../assets/images/home/programContentBg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 3em 6em;
    padding: 3em;
    position: relative;
    z-index: 1000 !important;
}
.leftImg{
    position: absolute !important;
    left: -15% !important;
    top: 0% !important;
    width: 250px;
    object-fit: contain !important;
    z-index: -5 !important;
}
.rightImg{
    position: absolute !important;
    right: -15% !important;
    top: 0% !important;
    width: 230px;
    object-fit: contain !important;
    z-index: -5 !important;
}