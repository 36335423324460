@import "assets/scss/main.scss";

.headerImg {
    display: flex;
    justify-content: center;
    align-items: center;

    .earlyYearsImg {
        width: 100%;
        
    }
    @media screen and (max-width: 600px){
        width: 100% !important;
        margin: 0 !important;
    }
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 2rem 0;
    text-align: center;
    h2{
        margin:0;
        letter-spacing: 0.3em;
        justify-content: space-between;
        font-size: xx-large !important;
        @media screen and (max-width: 600px) {
            letter-spacing: 0.15em;
        }
    }
    .firstTitle{
        color: $black !important;
        @media screen and (max-width: 600px) {
            margin-top: 1em;
        }
      }
      .secondTitle{
        color: $primaryColor !important ;
        @media screen and (max-width: 600px) {
            letter-spacing: 0.15em;
            padding: 0.5em 0.3em;
        }
      }
    @media screen and (max-width: 600px) {
        letter-spacing: 20px;
        font-size: 30px;
        justify-content: center;
        padding: 0;
    }
}

.primaryContent{
    font-family: $primaryFontName;
    font-size: 1em;
    margin: auto 15em;
    text-align: justify;
    line-height: 1.5;
    @media screen and (max-width: 600px) {
        text-align: justify;
        margin: 0 1em;
        font-size: 1.1em;
        padding: 0 1em;
        margin-bottom: 1em !important;
    }
}

.clr20{
    clear: both;
    height: 20px;
}

.clr10{
    clear: both;
    height: 10px;
}