@import "../../../assets/scss/main.scss";

.programMobWrapper{
    padding: 2em;
    border: 4px solid $primaryColor;
    border-radius: 2em;
    margin: 2em 0em;
    text-align: justify;
    img{
        margin: auto;
    }
}